import React from 'react'
import yesSVG from '../../img/yes.svg'
import noSVG from '../../img/no.svg'

function HasWebsiteQuestion(props) {

  const handleSelect = (res) => {
    props.responses.push(res)
    props.setHasWebsite(res)
    if ( res === 'hasWebsite' ) {
      props.setLandingPageCount(props.landingPageCount + 3)
      } else {
        if (props.businessOffer === 'product') {
          props.setECommerceCount(props.eCommerceCount + 1)
        } else if (props.businessOffer === 'service') {
          props.setLandingPageCount(props.landingPageCount + 1)
          props.setWebsiteCount(props.websiteCount + 1)
        } else if (props.businessOffer === 'event') {
          props.setLandingPageCount(props.landingPageCount + 1)
          props.setWebsiteCount(props.websiteCount + 1)
          props.setECommerceCount(props.eCommerceCount + 1)
        }
      }
  }

  return (
    <div className='Quiz'>
      <p className='Quiz-title'>Ya tiene una pagina web?</p>
      <form className='Quiz-form' action="">
        <label className='Quiz-card' htmlFor="yes">
        <img src={yesSVG} alt="" className='Quiz-card-option' />
        <p>Si</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="hasWebsite" value="hasWebsite" id="yes" />
        </label>
        <label className='Quiz-card' htmlFor="no">
        <img src={noSVG} alt="" className='Quiz-card-option' />
        <p>No</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="hasWebsite" value="dontHasWebsite" id="no" />
        </label>
      </form>
    </div>
  )
}

export default HasWebsiteQuestion