import React from 'react'

function QuantityQuestion(props) {

  const handleSelect = (quantity) => {
    props.responses.push(quantity)
    props.setQuantity(quantity)
    if ( quantity === '1' || quantity === '2') {
      if ( props.businessOffer === 'product' || props.businessOffer === 'service') {
        props.setWebsiteCount(props.websiteCount + 1)
        props.setLandingPageCount(props.landingPageCount + 1)
        props.setECommerceCount(props.eCommerceCount - 4)
      } else {
        props.setLandingPageCount(props.landingPageCount + 1)
        props.setWebsiteCount(props.websiteCount + 1)
        props.setECommerceCount(props.eCommerceCount - 4)
      }
    } else if ( quantity === '3' ) {
      if ( props.businessOffer === 'product') {
        props.setECommerceCount(props.eCommerceCount + 1)
      } else if ( props.businessOffer === 'service') {
        props.setWebsiteCount(props.websiteCount + 1)
        props.setECommerceCount(props.eCommerceCount + 1)
      } else {
        props.setWebsiteCount(props.websiteCount + 1)
        props.setECommerceCount(props.eCommerceCount + 1)
      }
    } else {
      if ( props.businessOffer === 'service')
      props.setWebsiteCount(props.websiteCount + 1)
    }
  }

  return (
    <div>
      <div className='Quiz'>
        <p className='Quiz-title' >Que cantidad de productos? {props.quantity}</p>
        <form className='Quiz-form' action="">
          <label className='Quiz-card flex flex-col  justify-center' htmlFor="1">
          <p className='text-6xl'>1</p>
          <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="Quantity" value="1" id="1" />
          </label>
          <label className='Quiz-card flex flex-col justify-center' htmlFor="2">
          <p className='text-6xl'>2</p>
          <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="Quantity" value="2" id="2" />
          </label>
          <label className='Quiz-card flex flex-col justify-center' htmlFor="3">
          <p className='text-6xl'>3</p>
          <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="Quantity" value="3" id="3" />
          </label>
          <label className='Quiz-card flex flex-col justify-center' htmlFor="4+">
          <p className='text-6xl'>4+</p>
          <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="Quantity" value="4+" id="4+" />
          </label>
        </form>
      </div>
    </div>
  )
}

export default QuantityQuestion