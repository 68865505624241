import React from 'react'
import CallToAction from './landing/CallToAction'
import Footer from './landing/Footer'
import Header from './landing/Header'
import Hero from './landing/Hero'
import Solutions from './landing/Solutions'

function Home() {
  return (
    <div className='w-full h-hull'>
        <Header/>
        <Hero/>
        <Solutions/>
        <CallToAction/>
       
        <Footer/>
    </div>
  )
}

export default Home