
import React from 'react'
import { Link } from 'react-router-dom'

function Hero() {
  return (
    <div className="pt-32 pb-24 bg-[url('/src/img/hero-background.jpg')] bg-cover" >
        <div className='container lg:px-8 xl:px-20 mx-auto flex flex-col space-y-8 text-white'>
            <h1 className='text-3xl w-5/6 text-center mx-auto  lg:w-1/2 lg:mx-0 lg:text-start lg:text-5xl'>Como escoger el mejor canal de ventas para mi negocio online?</h1>
            <h2 className='text-xl w-5/6 mx-auto text-center lg:w-1/2 lg:mx-0 lg:text-start lg:text-2xl' >Este test te da la respuesta en 5 minutos.</h2>
            <Link className='bg-yellow-500 p-4 w-36 text-center mx-auto lg:mx-0 text-lg' to={'/quiz'} > Hacer el test </Link>
        </div>
    </div>
  )
}

export default Hero