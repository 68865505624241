import React from 'react'
import yesSVG from '../../img/yes.svg'
import noSVG from '../../img/no.svg'

function HasCustomPricingQuestion(props) {

  const handleSelect = (res) => {
    props.responses.push(res)
    props.setHasCustomPricing(res)
    if ( res === 'hasCustomPricing' ) {
      props.setLandingPageCount(props.landingPageCount + 1)
      }
    }

  return (
    <div className='Quiz'>
      <p className='Quiz-title'>El costo de tu servicio puede variar segun las necesidades especificas del cliente?</p>
      <form className='Quiz-form' action="">
        <label className='Quiz-card' htmlFor="hasCustomPricing">
          <img src={yesSVG} alt="" className='Quiz-card-option' />
          <p>Si</p>
          <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="custom" value="hasCustomPricing" id="hasCustomPricing" />
        </label>
        <label className='Quiz-card' htmlFor="hasNotCustomPricing">
        <img src={noSVG} alt="" className='Quiz-card-option' />
          <p>No</p>
          <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="custom" value="hasNotCustomPricing" id="hasNotCustomPricing" />
        </label>
      </form>
    </div>
  )
}

export default HasCustomPricingQuestion