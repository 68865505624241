import React, { useState } from 'react'
import HasCustomPricingQuestion from './questions/HasCustomPricingQuestion'
import HasWebsiteQuestion from './questions/HasWebsiteQuestion'
import IsCustomizableQuestion from './questions/IsCustomizableQuestion'
import NeedsQuestion from './questions/NeedsQuestion'
import QuantityQuestion from './questions/QuantityQuestion'
import TypeQuestion from './questions/TypeQuestion'
import Header from './landing/Header'
import SubmitForm from './SubmitForm'
import { useEffect } from 'react'

function Quiz() {
    // scoring counters
    const [ websiteCount, setWebsiteCount ] = useState(0)
    const [ landingPageCount, setLandingPageCount ] = useState(0)
    const [ eCommerceCount, setECommerceCount ] = useState(0)

    // payload for lead generation
    const [ result, setResult ] = useState() 
    const [ responses, setResponses ] = useState([])    
    
    // quiz responses
    const [ businessOffer, setBusinessOffer ] = useState('')
    const [ quantity, setQuantity ] = useState()
    const [ hasWebsite, setHasWebsite ] = useState()
    const [ goal, setGoal ] = useState('')
    const [ isCustomizable, setIsCustomizable ] = useState()
    const [ hasCustomPricing, setHasCustomPricing ] = useState()
    
    // calculate result every time a scoring counter is updated and save on result state
    useEffect(()=> {
      const arr = [websiteCount, landingPageCount, eCommerceCount] 
      const max = Math.max(...arr);
      const index = arr.indexOf(max);
      if (index === 0) {
        setResult('website')
      } else if (index === 1 ) {
        setResult('landing')
      } else {
        setResult('ecommerce')
      }
    }, [websiteCount, landingPageCount, eCommerceCount])

  return (
    <div className="w-screen h-screen flex flex-col justify-center text-center">

        {!businessOffer && 
        <TypeQuestion 
          setBusinessOffer={setBusinessOffer} businessOffer={businessOffer} setWebsiteCount={setWebsiteCount}
          websiteCount={websiteCount} setLandingPageCount={setLandingPageCount} landingPageCount={landingPageCount} 
          setECommerceCount={setECommerceCount} eCommerceCount={eCommerceCount} responses={responses} setResponses={setResponses}
        /> }

        {!quantity && businessOffer && 
        <QuantityQuestion setQuantity={setQuantity} quantity={quantity} setBusinessOffer={setBusinessOffer} businessOffer={businessOffer} setWebsiteCount={setWebsiteCount}
          websiteCount={websiteCount} setLandingPageCount={setLandingPageCount} landingPageCount={landingPageCount} 
          setECommerceCount={setECommerceCount} eCommerceCount={eCommerceCount} responses={responses} setResponses={setResponses}
        /> }

        {!hasWebsite && quantity && 
        <HasWebsiteQuestion setHasWebsite={setHasWebsite} hasWebsite={hasWebsite} quantity={quantity}
          businessOffer={businessOffer} setWebsiteCount={setWebsiteCount}
          websiteCount={websiteCount} setLandingPageCount={setLandingPageCount} landingPageCount={landingPageCount} 
          setECommerceCount={setECommerceCount} eCommerceCount={eCommerceCount} responses={responses} setResponses={setResponses}
        />}

        {!goal && hasWebsite && 
        <NeedsQuestion setGoal={setGoal} goal={goal} businessOffer={businessOffer} setWebsiteCount={setWebsiteCount}
          websiteCount={websiteCount} setLandingPageCount={setLandingPageCount} landingPageCount={landingPageCount} 
          setECommerceCount={setECommerceCount} eCommerceCount={eCommerceCount} responses={responses} setResponses={setResponses}
          />}


        {businessOffer === 'product' && 
          !isCustomizable && goal &&
          <IsCustomizableQuestion setIsCustomizable={setIsCustomizable} responses={responses} setResponses={setResponses} setECommerceCount={setECommerceCount} eCommerceCount={eCommerceCount}
        />}

        
        {businessOffer === 'service' &&
          !hasCustomPricing && goal &&
          <HasCustomPricingQuestion setHasCustomPricing={setHasCustomPricing} responses={responses} setResponses={setResponses} landingPageCount={landingPageCount} setLandingPageCount={setLandingPageCount}
        />}

        {businessOffer === 'product' && 
        isCustomizable && <SubmitForm businessOffer={businessOffer} quantity={quantity} hasWebsite={hasWebsite} 
        goal={goal} isCustomizable={isCustomizable} hasCustomPricing={hasCustomPricing} result={result} responses={responses}
        />}

        {businessOffer === 'service' && 
        hasCustomPricing && <SubmitForm businessOffer={businessOffer} quantity={quantity} hasWebsite={hasWebsite} 
        goal={goal} isCustomizable={isCustomizable} hasCustomPricing={hasCustomPricing} result={result} responses={responses}
        />}

        {businessOffer === 'event' && 
        goal && <SubmitForm businessOffer={businessOffer} quantity={quantity} hasWebsite={hasWebsite} 
        goal={goal} isCustomizable={isCustomizable} hasCustomPricing={hasCustomPricing} result={result} responses={responses}
        />}
        
        
    </div>
  )
}

export default Quiz