import React from 'react'
import { useState, useEffect } from 'react';
import BenefitsCards from './BenefitsCards';

export const BodyOutcome = ({outcomeData}) => {

  const [ benefits, setBenefits ] = useState([]);

  useEffect(()=> {
    setBenefits(outcomeData)
  }, [])


  

  return (
   <>
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Obtene estos beneficios.</p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.</p>
        </div>
        <div className='flex flex-wrap m-4'>
          {Object.keys(benefits).map((benefit, i) => {
            {return <BenefitsCards key={i} title={benefits[benefit].title} description={benefits[benefit].description} />}
          })
          }
        </div>

      </div>
    </div>   
  </>
  )
}