import React from 'react'
import Image from './image'
  

function Error404 () {
 return (
    <section class="text-gray-600 body-font">
    <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
        <div class="">
        <Image class="w-12"></Image>
        </div>
       
      <div class="text-center lg:w-2/3 w-full">
        <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Error 404</h1>
        <p class="mb-8 leading-relaxed">Pagina no encontrada , intentalo mas tarde</p>
        <div class="flex justify-center">
           
        </div>
      </div>
    </div>
  </section>
)

}
export default Error404