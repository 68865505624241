import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Footer from './landing/Footer'
// import BodyOutcome from './outcome/BodyOutcome'
import CallToActionOutcome from './outcome/CallToActionOutcome'
import HeaderOutcome from './outcome/HeaderOutcome'
import HeroOutcome from './outcome/HeroOutcome'
import db from '../utils/firebase';
import { doc, getDoc } from "firebase/firestore";
import { BodyOutcome } from './outcome/BodyOutcome'


function Outcome() {

  const params = useParams()
  const navigate = useNavigate()
  const [ collectionId, setCollectionId ] = useState('')
  const [ outcomeData, setOutcomeData ] = useState()

  const onBadParams = () => {
    navigate('/home')
  }


  useEffect(() => {
    if (params.outcome === 'ecommerce') {
      setCollectionId('mZxEDOz1aD5BLUPWfNEX')
    } else if (params.outcome === 'landing') {
      setCollectionId('H4MCPS8LloZVJgnprntC')
    } else if (params.outcome === 'website') {
      setCollectionId('3fbe4kdmiDcpmUaWtSUS')
    } else {
      onBadParams()
    }
    
  }, [params])


  useEffect(() => {
    const getOutcomeData = async () => {
      const docRef = doc(db, "outcomes", collectionId)
      await getDoc(docRef)
        .then( (res) => {
          setOutcomeData(res.data())
          
        })
        .catch(err => console.log(err))
    }

    collectionId && getOutcomeData()

  }, [collectionId])

  return (
    <>
      {outcomeData &&  <> 
        <HeaderOutcome />
        <HeroOutcome outcomeData={outcomeData} />
        <BodyOutcome outcomeData={outcomeData.benefits} />
        <CallToActionOutcome/> <Footer/> 
        </>}    
    </>

  )
}

export default Outcome