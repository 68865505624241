import React from 'react'
import contactSVG from '../../img/contact.svg'
import salesSVG from '../../img/sales.svg'
import comingSoonSVG from '../../img/coming-soon.svg'

function NeedsQuestion(props) {

  const handleSelect = (res) => {
    props.responses.push(res)
    props.setGoal(res)
    if ( res === 'leads' ) {
      props.setLandingPageCount(props.landingPageCount + 2)
    } else if ( res === 'sellMore' ) {
      if ( props.quantity === 1 ) {
        props.setLandingPageCount(props.landingPageCount + 2)
      } else {
        props.setECommerceCount(props.eCommerceCount + 2)
      }
    } else {
      props.setLandingPageCount(props.landingPageCount + 2)
    }
  }
  
  return (
    <div className='Quiz'>
      <p className='Quiz-title' >Cual de los siguientes objetivos de ajusta a la necesidad principal de tu negocio en este momento?</p> 
      <form className='Quiz-form' action="">
        <label className='Quiz-card' htmlFor="leads">
        <img src={contactSVG} alt="" className='Quiz-card-option' />
        <p className=" text-center text-xs">Generar una base de <br className='md:hidden' /> datos de clientes/leads</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="goal" value="leads" id="leads" />
        </label>
        <label className='Quiz-card' htmlFor="sellMore">
        <img src={salesSVG} alt="" className='Quiz-card-option' />
        <p className=" text-center text-xs">Automatizar/potenciar <br className='md:hidden' /> tus ventas</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="goal" value="sellMore" id="sellMore" />
        </label>
        <label className='Quiz-card' htmlFor="productLaunch">
        <img src={comingSoonSVG} alt="" className='Quiz-card-option' />
        <p className=" text-center text-xs">Promocionar un nuevo <br className='md:hidden' /> lanzamiento</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="goal" value="productLaunch" id="productLaunch" />
        </label>
      </form>
    </div>
  )
}

export default NeedsQuestion