import React from 'react'

function HeroOutcome( {outcomeData} ) {

  console.log();

  return (
      <>
    <div className="pt-32 h-fit pb-24 bg-[url('/src/img/background2.jpg')] bg-cover" >
        <div className='container lg:px-8 xl:px-30 mx-auto  flex flex-col space-y-8 text-amber-400'>
            <h1 className='text-center font-extrabold px-8 text-5xl'>Cual es el tipo de web ideal para tu negocio?</h1>
            <h2 className='text-xl w-5/6 mx-auto text-center  lg:text-2xl' >Tu respuesta es:</h2>
            <h3 className='bg-white text-center text-black lg:text-3xl font-extrabold pt-3 self-center h-16 w-60' > {outcomeData.result} </h3>
        </div>
    </div>
    
    <div className='h-56 bg-amber-400'>
        <h3 className='pt-16 font-bold text-xl w-5/6 mx-auto text-center  lg:text-2xl'> {outcomeData.description} </h3>

    </div>

    </>

    
  )
}

export default HeroOutcome