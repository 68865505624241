import React from 'react'
import yesSVG from '../../img/yes.svg'
import noSVG from '../../img/no.svg'

function IsCustomizableQuestion(props) {

  const handleSelect = (res) => {
    props.responses.push(res)
    props.setIsCustomizable(res)
    if ( res === 'IsCustomizable' ) {
      props.setECommerceCount(props.eCommerceCount + 1)
      }
    }

  return (
    <div className='Quiz'>
      <p className='Quiz-title' >Tu producto es customizable?</p>
      <form className='Quiz-form' action="">
        <label className='Quiz-card' htmlFor="IsCustomizable">
        <img src={yesSVG} alt="" className='Quiz-card-option' />
        <p>Si</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="custom" value="IsCustomizable" id="IsCustomizable" />
        </label>
        <label className='Quiz-card' htmlFor="IsNotCustomizable">
        <img src={noSVG} alt="" className='Quiz-card-option' />
        <p>No</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="custom" value="IsNotCustomizable" id="IsNotCustomizable" />
        </label>
      </form>
    </div>
  )
}

export default IsCustomizableQuestion