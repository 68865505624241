import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDRFutfIlw6rmkBKWShXnxPFFtJ-PUgRlA",
    authDomain: "flipwebco-quiz-funnel.firebaseapp.com",
    projectId: "flipwebco-quiz-funnel",
    storageBucket: "flipwebco-quiz-funnel.appspot.com",
    messagingSenderId: "166244195514",
    appId: "1:166244195514:web:10df35b24b87b5eebab683"
  };
  


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
