import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
  return (
    <header className='fixed w-screen top-4 px-6 lg:px-12'>
        <div className='mx-auto flex justify-between ' >
            <div className='w-9 h-4' >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                version="1.1"
                viewBox="0 0 400 400"
              >
                <g fillRule="evenodd" stroke="none">
                  <path
                  className="fill-yellow-500"
                    d="M88.496 29.704c-6.194 6.2-8.096 8.337-8.096 9.1 0 .792 16.525 17.521 80.6 81.596 44.33 44.33 80.6 80.87 80.6 81.2 0 .33-36.27 36.87-80.6 81.2-64.788 64.787-80.6 80.798-80.6 81.613 0 1.435 15.382 16.787 16.821 16.787 1.107 0 179.979-178.494 179.979-179.599C277.2 200.813 97.984 21.6 97.196 21.6c-.332 0-4.247 3.647-8.7 8.104m67 .199c-4.772 4.777-8.296 8.616-8.296 9.039 0 .444 31.951 32.673 80.8 81.502 44.44 44.421 80.8 80.962 80.8 81.201 0 .24-36.36 36.771-80.8 81.182-48.161 48.129-80.8 81.039-80.8 81.472 0 1.001 16.015 16.901 17.023 16.901C165.15 381.2 344 202.526 344 201.599 344 200.943 164.852 21.6 164.196 21.6c-.222 0-4.137 3.737-8.7 8.303"
                  ></path>
                </g>
              </svg>
            </div>
            <Link className='bg-yellow-500 text-white p-3' to={'/quiz'} >HACER EL TEST</Link>
        </div>
    </header>
  )
}

export default Header