import React from 'react'
import { collection, addDoc } from "firebase/firestore";
import db from '../utils/firebase';
import { useNavigate } from 'react-router-dom';

import outcomeView from '../img/outcomeview.webp'

function SubmitForm(props) {

  const navigate = useNavigate()
  
  const onSubmitQuiz = async (e) => {
    e.preventDefault();
    let name = e.target[0].value
    let email = e.target[1].value
    let result = props.result
    let responses = props.responses
    let payload = { name, email, result, responses}
    console.log(payload);
    
    // Add a new document with a generated id.
    const docRef = collection(db, "quizreport")
    await addDoc(docRef, payload)
    console.log("Document written with ID: ", docRef.id);
    navigate(`/result-${result}`)
  }


  return (
    <section>
      <div className='border-4 w-10/12 md:w-fit max-w-4xl flex bg-yellow-500 mx-auto rounded-sm'>
        <div className="h-auto md:w-1/2 p-4 text-gray-700 flex flex-col justify-center">
          <h2 className='text-3xl' >El producto digital adecuado para potenciar tu negocio es:</h2>
          <h1 className='text-4xl text-white mt-6'>"
            {props.result ==="landing" ? <>Landing Page</> 
            : props.result === 'website' ? <>Pagina Web</> 
            : props.result === 'ecommerce' && <>E-Commerce / Tienda en linea</>}
          "</h1>
          <p className='mt-6'>Ingresa tu correo electronico aqui abajo para recibir un reporte mas detallado de tus resultados</p>
          <form onSubmit={onSubmitQuiz} className='mt-4'>
            <input className='h-12 w-10/12 px-3 mb-2 focus:outline-none focus:ring focus:ring-yellow-600' type="text" name="" id="" placeholder="nombre" /><br />
            <input className='h-12 w-10/12 px-3 focus:outline-none focus:ring focus:ring-yellow-600' type="email" name="" id="" placeholder="Correo electronico" /><br />
            <button  className='mt-6 h-12 border-2 border-white w-8/12 text-white rounded-sm hover:bg-white hover:text-yellow-500'>Continuar</button>
          </form>
          <p className='pt-2'>*No haremos spam ni venderemos tu informacion</p>
        </div>
        <div className='hidden md:flex w-1/2 bg-white'>
          <div className='w-full'>
            <h3 className='text-3xl pt-4 pb-2'>Obtene tu reporte GRATIS!</h3>
            <div className='flex justify-center'>
            <img src={outcomeView} alt="" width='400px' />
            </div>
            <div className="bg-yellow-400 pb-4">
              <button className='border-2 text-xl uppercase border-white text-white h-16 p-4 rounded-sm mt-4 hover:bg-white hover:text-yellow-500'>Obtene tu reporte ahora!</button>              
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubmitForm