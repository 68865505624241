import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Quiz from './components/Quiz';
import Outcome from './components/Outcome'
import Error404 from './components/Page404/Error404';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/quiz' element={<Quiz/>} />
        <Route exact path='/result-:outcome' element={<Outcome/>}/>
        <Route exact path='*' element={<Error404/>} />
      
      </Routes>
    </BrowserRouter>
  );
}

export default App;
