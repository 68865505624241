import React from 'react'
import { Link } from 'react-router-dom'

function CallToAction() {
  return (
    <div className="bg-[url('/src/img/yellow-patterns.jpg')] py-14" >
        <div className="bg-white w-4/5 mx-auto">
            <div className="text-center w-full mx-auto py-10 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
                    <span className="block text-black">
                        Cual es el mejor producto web para tu negocio?
                    </span>
                    <span className="block text-3xl mt-4 text-gray-700">
                        Estas a punto de descubrirlo
                    </span>
                </h2>
                <p className="text-xl mt-4 max-w-md mx-auto text-gray-400">
                    Realiza el test de 5 minutos y recibi un reporte gratuito con la solucion web se mejor ajusta a la necesidad de tu negocio.
                </p>
                <div className="lg:mt-0 lg:flex-shrink-0">
                    <div className="mt-12 inline-flex rounded-md shadow">
                        <Link to='/quiz' className="py-4 px-6  bg-yellow-500 hover:bg-yellow-600 focus:ring-yellow-600 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                            HACER EL TEST
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CallToAction