import React from 'react'
import productSVG from '../../img/product.svg'
import serviceSVG from '../../img/service.svg'
import eventSVG from '../../img/event.svg'


function TypeQuestion( props ) {

  
  const handleSelect = (value) => {

    if (value === 'event') {
      props.setWebsiteCount(props.websiteCount + 1)
      props.setLandingPageCount(props.landingPageCount + 1)
    }
     
    props.responses.push(value)
    setTimeout( () => {
      props.setBusinessOffer(value)
    }, 150)
  }

  return (
    <div className='Quiz'>
      <p className='Quiz-title' >Que ofrece tu negocio? {props.businessOffer}</p>
      <form className='Quiz-form' action="">
        <label className='Quiz-card' htmlFor="product">
        <img src={productSVG} alt="" className='Quiz-card-option' />
        <p>Productos</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="type" value="product" id="product" />
        </label>
        <label className='Quiz-card' htmlFor="service">
        <img src={serviceSVG} alt="" className='Quiz-card-option'/>
        <p>Servicios</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="type" value="service" id="service" />
        </label>
        <label className='Quiz-card' htmlFor="event">
        <img src={eventSVG} alt="" className='Quiz-card-option' />
        <p>Eventos</p>
        <input onClick={(e) => handleSelect(e.target.value)} className="hidden" type="radio" name="type" value="event" id="event" />
        </label>
      </form>
    </div>
  )
}

export default TypeQuestion